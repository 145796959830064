import './flowbite.css';

// core components
import Accordion from './components/accordion'
import Collapse from './components/collapse'
import Carousel from './components/carousel'
import Dismiss from './components/dismiss'
import Dropdown from './components/dropdown'
import Modal from './components/modal'
import Tabs from './components/tabs'
import Tooltip from './components/tooltip'

export default {
    Accordion,
    Collapse,
    Carousel,
    Dismiss,
    Dropdown,
    Modal,
    Tabs,
    Tooltip,
}